import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import AuthContext from '../../contexts/AuthContext';
import { getSupabase } from '../../supabase';
import { QUIZ_COUNT_URL } from '../../config/api';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import CommentBox from '../common/CommentBox';
import FilterControls from '../common/FilterControls';
import AnalyticsChart from '../common/AnalyticsChart';
import DataTable from '../common/DataTable';
import StatusBadge from '../common/StatusBadge';
import ResultCard from '../common/ResultCard';
import MobileMenu from './MobileMenu';

const translations = {
  title: {
    ru: 'Панель администратора',
    kz: 'Әкімші панелі'
  },
  coaches: {
    ru: 'Коучи',
    kz: 'Коучтар'
  },
  manageCoaches: {
    ru: 'Управление коучами',
    kz: 'Коучтарды басқару'
  },
  results: {
    ru: 'Результаты',
    kz: 'Нәтижелер'
  },
  totalResults: {
    ru: 'Всего результатов',
    kz: 'Барлық нәтижелер'
  },
  search: {
    ru: 'Поиск по имени, email или телефону',
    kz: 'Аты, email немесе телефон бойынша іздеу'
  },
  name: {
    ru: 'Имя',
    kz: 'Аты-жөні'
  },
  userEmail: {
    ru: 'Email пользователя',
    kz: 'Қолданушы email'
  },
  assignedCoach: {
    ru: 'Назначенный коуч',
    kz: 'Тағайындалған коуч'
  },
  date: {
    ru: 'Дата',
    kz: 'Күні'
  },
  actions: {
    ru: 'Действия',
    kz: 'Әрекеттер'
  },
  viewResult: {
    ru: 'Просмотр результата',
    kz: 'Нәтижені қарау'
  },
  noResults: {
    ru: 'Результаты не найдены',
    kz: 'Нәтижелер табылмады'
  },
  addCoach: {
    ru: 'Добавить коуча',
    kz: 'Коуч қосу'
  },
  showAdmins: {
    ru: 'Показать администраторов',
    kz: 'Әкімшілерді көрсету'
  },
  loading: {
    ru: 'Загрузка...',
    kz: 'Жүктелуде...'
  },
  viewResults: {
    ru: 'Посмотреть результаты',
    kz: 'Нәтижелерді көру'
  },
  language: {
    ru: 'Қазақша',
    kz: 'Русский'
  },
  totalTests: {
    ru: 'Всего тестов',
    kz: 'Барлық тесттер'
  },
  viewAll: {
    ru: 'Посмотреть все',
    kz: 'Барлықтарды көру'
  },
  totalCoaches: {
    ru: 'Всего коучей',
    kz: 'Барлық коучтар'
  },
  latestResult: {
    ru: 'Последний результат',
    kz: 'Соңғы нәтиже'
  },
  status: {
    ru: 'Статус',
    kz: 'Статус'
  },
  testInProgress: {
    ru: 'Тест в процессе',
    kz: 'Тест үдерісте'
  },
  testIncomplete: {
    ru: 'Тест не завершен',
    kz: 'Тест аяқталмаған'
  },
  startDate: {
    ru: 'Начало',
    kz: 'Басталу'
  },
  endDate: {
    ru: 'Конец',
    kz: 'Аяқталу'
  },
  analytics: {
    ru: 'Аналитика',
    kz: 'Талдау'
  },
  started: {
    ru: 'Начали тест',
    kz: 'Тестті бастады'
  },
  completed: {
    ru: 'завершено',
    kz: 'аяқталды'
  },
  selectCoach: {
    ru: 'Коуч',
    kz: 'Коучты таңдаңыз'
  },
  allCoaches: {
    ru: 'Все коучи',
    kz: 'Барлық коучтар'
  },
  overallStats: {
    ru: 'Общая статистика',
    kz: 'Жалпы статистика'
  },
  totalStarted: {
    ru: 'Всего начали',
    kz: 'Барлығы бастады'
  },
  totalCompleted: {
    ru: 'Всего завершили',
    kz: 'Барлығы аяқтады'
  },
  overallConversion: {
    ru: 'Общая конверсия',
    kz: 'Жалпы конверсия'
  },
  averagePerDay: {
    ru: 'В среднем в день',
    kz: 'Күніне орташа'
  },
  analyticsDateRange: {
    ru: 'Период аналитики',
    kz: 'Талдау кезеңі'
  },
  finances: {
    ru: 'Финансы',
    kz: 'Қаржы'
  },
  viewFinancials: {
    ru: 'Просмотр финансов',
    kz: 'Қаржыларды қарау'
  },
  hasComment: {
    ru: 'Комментарий',
    kz: 'Пікір'
  },
  noComment: {
    ru: 'Нет комментария',
    kz: 'Пікір жоқ'
  },
  reviewStatus: {
    ru: 'Проведено',
    kz: 'Өткізілді'
  },
  reset: {
    ru: 'Сбросить',
    kz: 'Қалпына келтіру'
  },
  apply: {
    ru: 'Применить',
    kz: 'Қолдану'
  },
  all: {
    ru: 'Все',
    kz: 'Барлығы'
  },
  select: {
    ru: 'Выберите',
    kz: 'Таңдаңыз'
  },
  showComment: {
    ru: 'Показать комментарий',
    kz: 'Пікірді көрсету'
  },
  hideComment: {
    ru: 'Скрыть комментарий',
    kz: 'Пікірді жасыру'
  },
  reviewed: {
    ru: 'Проведено',
    kz: 'Өткізілді'
  },
  notReviewed: {
    ru: 'Не проведено',
    kz: 'Өткізілмеген'
  },
  quizIncomplete: {
    ru: 'Тест не завершен',
    kz: 'Тест аяқталмаған'
  },
  quizInProgress: {
    ru: 'Тест в процессе',
    kz: 'Тест үдерісте'
  },
  totalAttempts: {
    ru: 'всего попыток',
    kz: 'барлық әрекеттер'
  }
};

const AdminDashboard = () => {
  const { language } = useContext(LanguageContext);
  const { logout } = useContext(AuthContext);
  
  const [results, setResults] = useState([]);
  const [mappedResults, setMappedResults] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState('all');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedCoach, setSelectedCoach] = useState('');
  const [savingCommentId, setSavingCommentId] = useState(null);
  const [isAnalyticsExpanded, setIsAnalyticsExpanded] = useState(false);
  const [analyticsStartDate, setAnalyticsStartDate] = useState('');
  const [analyticsEndDate, setAnalyticsEndDate] = useState('');
  const [realQuizCount, setRealQuizCount] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
  // Fetch real quiz count from API
  useEffect(() => {
    const fetchQuizCount = async () => {
      try {
        const response = await fetch(QUIZ_COUNT_URL);
        const data = await response.json();
        setRealQuizCount(data.count);
      } catch (err) {
        console.error('Error fetching quiz count:', err);
      }
    };
    
    fetchQuizCount();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch test results
        const { data: resultsData, error: resultsError } = await getSupabase()
          .from('quiz_results')
          .select(`
            id,
            created_at,
            entered_name,
            entered_phone,
            answers,
            current_index,
            is_random,
            coach_id,
            user_id,
            calculated_results,
            review_status,
            comment,
            user:user_id (
              email
            ),
            coach:coach_id (
              name,
              email
            )
          `)
          .order('created_at', { ascending: false });
          
        if (resultsError) throw resultsError;
        
        // Map the data to maintain compatibility with existing code
        const mappedResults = resultsData.map(result => ({
          ...result,
          user_name: result.entered_name || '—',
          user_email: result.user?.email,
          user_phone: result.entered_phone || '—',
          coach_email: result.coach?.email,
          coach_name: result.coach?.name
        }));
        
        setMappedResults(mappedResults);
        
        // Process all results to determine their status
        const resultsWithStatus = mappedResults.map(result => {
          // A test is complete if it has calculated results
          const isComplete = result.calculated_results !== null;
          
          // Calculate time since test creation
          const createdAt = new Date(result.created_at);
          const now = new Date();
          const hoursSinceCreated = (now - createdAt) / (1000 * 60 * 60);
          
          // A test is in progress if:
          // - Created less than 2 hours ago
          // - Has some answers but no calculated results
          const hasAnswers = result.answers && Object.keys(result.answers).length > 0;
          const isInProgress = !isComplete && hoursSinceCreated < 2 && hasAnswers;
          
          return {
            ...result,
            status: isComplete ? 'complete' : (isInProgress ? 'in_progress' : 'incomplete')
          };
        });
        
        // Sort by creation date to ensure most recent first
        const sortedResults = resultsWithStatus.sort((a, b) => 
          new Date(b.created_at) - new Date(a.created_at)
        );
        
        // Fetch coaches
        const { data: coachesData, error: coachesError } = await getSupabase()
          .from('approved_coaches')
          .select('*')
          .order('name', { ascending: true });
          
        if (coachesError) throw coachesError;
        
        setResults(sortedResults);
        setCoaches(coachesData || []);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);
  
  // Calculate overall stats
  const overallStats = useMemo(() => {
    let filteredResults = mappedResults || [];
    
    // Apply date range filter
    if (analyticsStartDate || analyticsEndDate) {
      filteredResults = filteredResults.filter(result => {
        const resultDate = new Date(result.created_at);
        return (!analyticsStartDate || resultDate >= new Date(analyticsStartDate)) && 
               (!analyticsEndDate || resultDate <= new Date(analyticsEndDate + 'T23:59:59'));
      });
    }

    // Apply coach filter
    if (selectedCoach) {
      filteredResults = filteredResults.filter(result => result.coach_email === selectedCoach);
    }

    // Total started is all attempts
    const totalStarted = filteredResults.length;
    
    // Total completed is only those with calculated_results
    const totalCompleted = filteredResults.filter(r => r.calculated_results !== null).length;
    
    const conversion = totalStarted > 0 ? Math.round((totalCompleted / totalStarted) * 100) : 0;
    
    // Calculate average per day
    let averageStarted = 0;
    let averageCompleted = 0;
    
    if (totalStarted > 0) {
      const firstDate = new Date(Math.min(...filteredResults.map(r => new Date(r.created_at))));
      const lastDate = new Date(Math.max(...filteredResults.map(r => new Date(r.created_at))));
      const daysDiff = Math.max(1, Math.ceil((lastDate - firstDate) / (1000 * 60 * 60 * 24)));
      
      averageStarted = Math.round((totalStarted / daysDiff) * 10) / 10;
      averageCompleted = Math.round((totalCompleted / daysDiff) * 10) / 10;
    }

    return {
      totalStarted,
      totalCompleted,
      conversion,
      averageStarted,
      averageCompleted
    };
  }, [mappedResults, analyticsStartDate, analyticsEndDate, selectedCoach]);

  // Calculate analytics data for the chart
  const analyticsData = useMemo(() => {
    let startDate = new Date();
    let endDate = new Date();
    let days = [];

    if (analyticsStartDate && analyticsEndDate) {
      startDate = new Date(analyticsStartDate);
      endDate = new Date(analyticsEndDate);
    } else {
      // Default to last 10 days if no date range selected
      startDate.setDate(startDate.getDate() - 9);
    }

    // Calculate number of days between dates
    const dayDiff = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
    
    for (let i = 0; i < dayDiff; i++) {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      date.setHours(0, 0, 0, 0);
      
      const nextDate = new Date(date);
      nextDate.setDate(date.getDate() + 1);
      
      const dayResults = mappedResults.filter(result => {
        const resultDate = new Date(result.created_at);
        return resultDate >= date && resultDate < nextDate &&
               (!selectedCoach || result.coach_email === selectedCoach);
      });
      
      const started = dayResults.length;
      const completed = dayResults.filter(r => r.calculated_results !== null).length;
      
      days.push({
        date: date.toLocaleDateString(),
        [translations.started[language]]: started,
        [translations.completed[language]]: completed
      });
    }
    
    return days;
  }, [mappedResults, selectedCoach, language, analyticsStartDate, analyticsEndDate]);
  
  // Filter results based on search term and date
  const filteredResults = results.filter(result => {
    const matchesSearch = searchTerm === '' || 
      (result.entered_name && result.entered_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (result.user?.email && result.user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (result.coach?.email && result.coach.email.toLowerCase().includes(searchTerm.toLowerCase()));
    
    // Check if the result's date is within the selected range
    const resultDate = new Date(result.created_at);
    const matchesDateRange = (!startDate || resultDate >= new Date(startDate)) && 
                            (!endDate || resultDate <= new Date(endDate + 'T23:59:59'));
    
    // Add coach filter
    const matchesCoach = !selectedCoach || result.coach?.email === selectedCoach;
    
    return matchesSearch && matchesDateRange && matchesCoach;
  });
  
  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };
  
  const handleStartDateChange = (value) => {
    setStartDate(value);
  };
  
  const handleEndDateChange = (value) => {
    setEndDate(value);
  };
  
  const handleCoachChange = (value) => {
    setSelectedCoach(value);
  };
  
  const handleSaveComment = async (resultId, comment) => {
    setSavingCommentId(resultId);
    try {
      const { error } = await getSupabase()
        .from('quiz_results')
        .update({ comment })
        .eq('id', resultId);
        
      if (error) throw error;
      
      // Update local state
      setResults(prev => prev.map(result => 
        result.id === resultId 
          ? { ...result, comment }
          : result
      ));
    } catch (err) {
      console.error('Error saving comment:', err);
      setError(err.message);
    } finally {
      setSavingCommentId(null);
    }
  };
  
  // Define translations for status
  const statusTranslations = {
    complete: {
      ru: 'Завершен',
      kz: 'Аяқталған'
    },
    in_progress: {
      ru: 'В процессе',
      kz: 'Үдерісте'
    },
    incomplete: {
      ru: 'Не завершен',
      kz: 'Аяқталмаған'
    }
  };
  
  const handleReviewStatusChange = async (id, newStatus) => {
    try {
      const { error } = await getSupabase()
        .from('quiz_results')
        .update({ review_status: newStatus })
        .eq('id', id);
        
      if (error) throw error;
      
      // Update local state
      setResults(prev => 
        prev.map(result => 
          result.id === id 
            ? { ...result, review_status: newStatus } 
            : result
        )
      );
    } catch (err) {
      console.error('Error changing review status:', err);
    }
  };
  
  // Format date helper
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    } catch (e) {
      return dateString;
    }
  };
  
  // Handle search filter apply - no longer needed as filtering happens automatically
  const handleFilterApply = () => {
    // Filters are applied automatically through the filteredResults
  };
  
  // Handle filter reset
  const handleFilterReset = () => {
    setSearchTerm('');
    setStartDate('');
    setEndDate('');
    setSelectedCoach('');
  };
  
  // Define column structure for DataTable
  const columns = [
    {
      header: translations.name[language],
      accessor: 'user_name',
      render: (result) => (
        <div className="flex items-center">
          <div>
            <div className="text-sm font-medium text-gray-900">
              {result.user_name}
            </div>
            <div className="text-sm text-gray-500">
              {result.user_phone}
            </div>
          </div>
        </div>
      )
    },
    {
      header: `${translations.userEmail[language]} / ${translations.assignedCoach[language]}`,
      accessor: 'user_email',
      render: (result) => (
        <div>
          <div className="text-sm text-gray-500 mb-1">
            {result.user_email || '—'}
          </div>
          <div className="text-sm text-gray-500">
            {result.coach_email || '—'}
          </div>
        </div>
      )
    },
    {
      header: `${translations.date[language]} / ${translations.status[language]}`,
      accessor: 'created_at',
      render: (result) => (
        <div>
          <div className="text-sm text-gray-500 mb-1">
            {formatDate(result.created_at)}
          </div>
          <StatusBadge 
            status={result.status} 
            translations={statusTranslations} 
            language={language} 
          />
        </div>
      )
    },
    {
      header: translations.actions[language],
      accessor: 'actions',
      className: 'text-center',
      render: (result) => (
        <div className="flex flex-col space-y-2">
          {result.status === 'complete' ? (
            <Link
              to={`/results/grid/${result.id}?lang=${language}`}
              className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-sm whitespace-nowrap inline-block h-10 flex items-center justify-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              {translations.viewResult[language]}
            </Link>
          ) : (
            <button
              disabled
              className="px-3 py-2 bg-gray-300 text-gray-600 rounded-md text-sm whitespace-nowrap inline-block h-10 flex items-center justify-center cursor-not-allowed"
            >
              {result.status === 'in_progress' 
                ? translations.quizInProgress[language]
                : translations.quizIncomplete[language]
              }
            </button>
          )}
          <a
            href={`https://wa.me/${result.user_phone?.replace(/[^0-9]/g, '')}`}
            target="_blank"
            rel="noopener noreferrer"
            className="px-3 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 text-sm whitespace-nowrap inline-block h-10 flex items-center justify-center"
          >
            {language === 'ru' ? 'Написать в WA' : 'Ватсапқа жазу'}
          </a>
        </div>
      )
    },
    {
      header: translations.reviewStatus[language],
      accessor: 'review_status',
      className: 'text-center',
      render: (result) => (
        <input
          type="checkbox"
          id={`review-status-${result.id}`}
          name={`review-status-${result.id}`}
          checked={result.review_status || false}
          onChange={() => handleReviewStatusChange(result.id, !result.review_status)}
          className="h-5 w-5 text-green-600 rounded"
          aria-label={`${translations.reviewStatus[language]} ${result.client_name || result.client_email}`}
        />
      )
    }
  ];

  // Add expandable row with comment box for DataTable
  const expandableContent = (result) => (
    <div className="p-3 bg-gray-50">
      <div className="text-sm text-gray-600 mb-1">{translations.hasComment[language]}:</div>
      <CommentBox
        resultId={result.id}
        initialComment={result.comment || ''}
        onSaveComment={handleSaveComment}
        isSaving={savingCommentId === result.id}
        className="w-full"
      />
    </div>
  );
  
  // Define coach options for select dropdown
  const coachOptions = coaches.map(coach => ({
    id: coach.id,
    value: coach.email,
    label: coach.name || coach.email
  }));
  
  // Define chart series data
  const chartSeries = [
    { 
      dataKey: translations.started[language], 
      name: translations.started[language], 
      color: '#3B82F6' 
    },
    { 
      dataKey: translations.completed[language], 
      name: translations.completed[language], 
      color: '#22C55E' 
    }
  ];
  
  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <p className="text-gray-600">{translations.loading[language]}</p>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="bg-red-100 text-red-700 p-4 rounded-md">
        <p>{error}</p>
      </div>
    );
  }
  
  return (
    <div className="flex flex-col gap-6">
      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {/* Total Tests Card */}
        <div className="bg-white rounded-lg shadow-sm p-4">
          <div className="flex items-center">
            <div className="p-2 mr-3 text-blue-500 bg-blue-100 rounded-full flex-shrink-0">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="min-w-0">
              <p className="mb-1 text-sm font-medium text-gray-600">{translations.totalTests[language]}</p>
              <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-700">
                  {mappedResults?.filter(r => r.calculated_results !== null).length || 0} <span className="text-sm font-normal text-gray-500">{translations.completed[language]}</span>
                </p>
                <p className="text-sm text-gray-500">
                  {mappedResults?.length || 0} <span className="text-xs">{translations.totalAttempts[language]}</span>
                </p>
              </div>
              <Link to="/admin/dashboard" className="text-xs text-blue-500 hover:underline block truncate">{translations.viewAll[language]}</Link>
            </div>
          </div>
        </div>

        {/* Total Coaches Card */}
        <div className="bg-white rounded-lg shadow-sm p-4">
          <div className="flex items-center">
            <div className="p-2 mr-3 text-green-500 bg-green-100 rounded-full flex-shrink-0">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
              </svg>
            </div>
            <div className="min-w-0">
              <p className="mb-1 text-sm font-medium text-gray-600">{translations.totalCoaches[language]}</p>
              <p className="text-lg font-semibold text-gray-700">{coaches.length}</p>
              <Link to="/admin/coaches" className="text-xs text-blue-500 hover:underline block truncate">{translations.viewAll[language]}</Link>
            </div>
          </div>
        </div>

        {/* Latest Result Card */}
        <div className="bg-white rounded-lg shadow-sm p-4">
          <div className="flex items-center">
            <div className="p-2 mr-3 text-purple-500 bg-purple-100 rounded-full flex-shrink-0">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="min-w-0">
              <p className="mb-1 text-sm font-medium text-gray-600">{translations.latestResult[language]}</p>
              <p className="text-lg font-semibold text-gray-700 truncate">
                {results.find(r => r.status === 'complete')?.user_name || '—'}
              </p>
              <Link 
                to={results.find(r => r.status === 'complete') 
                  ? `/admin/results/${results.find(r => r.status === 'complete').id}` 
                  : '#'} 
                className="text-xs text-blue-500 hover:underline block truncate"
              >
                {translations.viewResults[language]}
              </Link>
            </div>
          </div>
        </div>

        {/* Finances Card */}
        <div className="bg-white rounded-lg shadow-sm p-4">
          <div className="flex items-center">
            <div className="p-2 mr-3 text-yellow-500 bg-yellow-100 rounded-full flex-shrink-0">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="min-w-0">
              <p className="mb-1 text-sm font-medium text-gray-600">{translations.finances[language]}</p>
              <p className="text-lg font-semibold text-gray-700">
                -
              </p>
              <Link to="/admin/finance" className="text-xs text-blue-500 hover:underline block truncate">
                {translations.viewFinancials[language]}
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Analytics Chart */}
      <div className="mb-8">
        <div className="flex items-center gap-2 cursor-pointer" onClick={() => setIsAnalyticsExpanded(!isAnalyticsExpanded)}>
          <h3 className="text-xl font-bold">{translations.analytics[language]}</h3>
          <svg 
            className={`w-6 h-6 transition-transform ${isAnalyticsExpanded ? 'rotate-180' : ''}`} 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </div>
        {isAnalyticsExpanded && (
          <>
            {/* Overall Stats Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mt-4 mb-6">
              <div className="bg-blue-50 p-4 rounded-lg">
                <div className="text-sm text-blue-600 font-medium">{translations.totalStarted[language]}</div>
                <div className="text-2xl font-bold text-blue-700">{overallStats.totalStarted}</div>
                <div className="text-sm text-blue-500 mt-1">∅ {overallStats.averageStarted}/день</div>
              </div>
              
              <div className="bg-green-50 p-4 rounded-lg">
                <div className="text-sm text-green-600 font-medium">{translations.totalCompleted[language]}</div>
                <div className="text-2xl font-bold text-green-700">{overallStats.totalCompleted}</div>
                <div className="text-sm text-green-500 mt-1">∅ {overallStats.averageCompleted}/день</div>
              </div>
              
              <div className="bg-purple-50 p-4 rounded-lg">
                <div className="text-sm text-purple-600 font-medium">{translations.overallConversion[language]}</div>
                <div className="text-2xl font-bold text-purple-700">{overallStats.conversion}%</div>
              </div>
              
              <div className="lg:col-span-2 bg-gray-50 p-4 rounded-lg">
                <div className="text-sm text-gray-600 font-medium mb-2">{translations.analyticsDateRange[language]}</div>
                <div className="flex gap-2">
                  <input
                    type="date"
                    value={analyticsStartDate}
                    onChange={(e) => setAnalyticsStartDate(e.target.value)}
                    className="flex-1 px-2 py-1 border rounded"
                  />
                  <input
                    type="date"
                    value={analyticsEndDate}
                    onChange={(e) => setAnalyticsEndDate(e.target.value)}
                    className="flex-1 px-2 py-1 border rounded"
                  />
                </div>
              </div>
            </div>

            {/* Chart - Replace with AnalyticsChart component */}
            <AnalyticsChart
              data={analyticsData}
              chartType="bar"
              series={chartSeries}
              xAxisDataKey="date"
              height={300}
              showLegend={true}
              translations={translations}
              language={language}
            />
          </>
        )}
      </div>
      
      {/* Search and Filter Controls - Replace with FilterControls component */}
      <FilterControls
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        startDate={startDate}
        onStartDateChange={handleStartDateChange}
        endDate={endDate}
        onEndDateChange={handleEndDateChange}
        selectOptions={coachOptions}
        selectedOption={selectedCoach}
        onSelectChange={handleCoachChange}
        onFilterReset={handleFilterReset}
        translations={translations}
        language={language}
      />
    </div>
  );
};

export default AdminDashboard;