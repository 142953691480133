import React, { useState, useEffect, useCallback } from 'react';
import { getSupabase } from '../../supabase';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useContext } from 'react';
import debounce from 'lodash/debounce';
import { toast } from 'react-hot-toast';

const translations = {
  ru: {
    userManagement: 'Управление пользователями',
    search: 'Поиск...',
    name: 'Имя',
    telegramUsername: 'Telegram username',
    phone: 'Телефон',
    registrationDate: 'Дата регистрации',
    actions: 'Действия',
    edit: 'Изменить',
    save: 'Сохранить',
    cancel: 'Отмена',
    deleteUser: 'Удалить пользователя',
    confirmDelete: 'Вы уверены, что хотите удалить этого пользователя?',
    userDeleted: 'Пользователь удален',
    userUpdated: 'Пользователь обновлен',
    error: 'Ошибка',
    noUsersFound: 'Пользователи не найдены',
    loading: 'Загрузка...',
    telegramHandle: 'Telegram аккаунт',
    searchByNamePhoneTelegram: 'Поиск по имени, телефону или Telegram',
    quizStatus: 'Статус квиза',
    passed: 'Пройден',
    notPassed: 'Не пройден',
    firstName: 'Имя',
    lastName: 'Фамилия',
    transactions: 'Транзакции',
    viewTransactions: 'Смотреть транзакции',
    noTransactions: 'Нет транзакций',
    amount: 'Сумма',
    date: 'Дата',
    paymentMethod: 'Способ оплаты',
    plan: 'План',
    close: 'Закрыть',
    transactionHistory: 'История транзакций',
    assignTelegram: 'Назначить Telegram',
    paymentStatus: 'Статус оплаты',
    notPaid: 'Не оплачено',
    pendingConfirmation: 'Ожидает подтверждения',
    confirmed: 'Подтверждено',
    productPurchased: 'Купленный продукт',
    miniLessons: 'Мини-уроки (5000 ₸)',
    oneOnOne: '1-на-1 с Куланом (25000 ₸)',
    subscription: 'Подписка Potential Space',
    confirmedBy: 'Подтверждено',
    zoomEmail: 'Zoom Email',
    telegramGroup: 'Группа в Telegram',
    notInGroup: 'Не в группе',
    inGroup: 'В группе',
    courseProgress: 'Прогресс курса',
    notStarted: 'Не начат',
    introCompleted: 'Вводный курс завершен',
    quizAttempts: 'Попытки квиза',
    addToGroup: 'Добавить в группу',
    confirmPayment: 'Подтвердить оплату',
    language: 'Язык',
    russian: 'Русский',
    kazakh: 'Казахский',
    joinedOn: 'Присоединился',
    subscriptionInfo: 'Информация о подписке',
    noSubscription: 'Нет подписки',
    months_3: '3 месяца',
    months_6: '6 месяцев',
    months_12: '12 месяцев',
    expiresOn: 'Истекает',
    active: 'Активна',
    expired: 'Истекла',
    renewedOn: 'Продлена'
  },
  kz: {
    userManagement: 'Пайдаланушыларды басқару',
    search: 'Іздеу...',
    name: 'Аты',
    telegramUsername: 'Telegram қолданушы аты',
    phone: 'Телефон',
    registrationDate: 'Тіркелген күні',
    actions: 'Әрекеттер',
    edit: 'Өзгерту',
    save: 'Сақтау',
    cancel: 'Болдырмау',
    deleteUser: 'Пайдаланушыны жою',
    confirmDelete: 'Бұл пайдаланушыны жойғыңыз келетініне сенімдісіз бе?',
    userDeleted: 'Пайдаланушы жойылды',
    userUpdated: 'Пайдаланушы жаңартылды',
    error: 'Қате',
    noUsersFound: 'Пайдаланушылар табылған жоқ',
    loading: 'Жүктелуде...',
    telegramHandle: 'Telegram аккаунт',
    searchByNamePhoneTelegram: 'Аты, телефон немесе Telegram бойынша іздеу',
    quizStatus: 'Тест мәртебесі',
    passed: 'Өтті',
    notPassed: 'Өтпеді',
    firstName: 'Аты',
    lastName: 'Тегі',
    transactions: 'Транзакциялар',
    viewTransactions: 'Транзакцияларды көру',
    noTransactions: 'Транзакциялар жоқ',
    amount: 'Сома',
    date: 'Күні',
    paymentMethod: 'Төлем әдісі',
    plan: 'Жоспар',
    close: 'Жабу',
    transactionHistory: 'Транзакция тарихы',
    assignTelegram: 'Telegramды тағайындау',
    paymentStatus: 'Төлем мәртебесі',
    notPaid: 'Төленбеген',
    pendingConfirmation: 'Растауды күтуде',
    confirmed: 'Расталған',
    productPurchased: 'Сатып алынған өнім',
    miniLessons: 'Мини-сабақтар (5000 ₸)',
    oneOnOne: 'Құланмен 1-ге-1 (25000 ₸)',
    subscription: 'Potential Space жазылым',
    confirmedBy: 'Растаған',
    zoomEmail: 'Zoom Email',
    telegramGroup: 'Telegram тобы',
    notInGroup: 'Топта емес',
    inGroup: 'Топта',
    courseProgress: 'Курс прогресі',
    notStarted: 'Басталмаған',
    introCompleted: 'Кіріспе курс аяқталды',
    quizAttempts: 'Тест әрекеттері',
    addToGroup: 'Топқа қосу',
    confirmPayment: 'Төлемді растау',
    language: 'Тіл',
    russian: 'Орысша',
    kazakh: 'Қазақша',
    joinedOn: 'Қосылды',
    subscriptionInfo: 'Жазылым туралы ақпарат',
    noSubscription: 'Жазылым жоқ',
    months_3: '3 ай',
    months_6: '6 ай',
    months_12: '12 ай',
    expiresOn: 'Аяқталады',
    active: 'Белсенді',
    expired: 'Аяқталған',
    renewedOn: 'Жаңартылған'
  }
};

const UserManagement = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language] || translations.ru;
  
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingUser, setEditingUser] = useState(null);
  const [viewingTransactions, setViewingTransactions] = useState(null);
  const [viewingUserDetails, setViewingUserDetails] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [subscriptions, setSubscriptions] = useState({});
  const [confirmingPayment, setConfirmingPayment] = useState(null);
  const [editFormData, setEditFormData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    phone: '',
    zoom_email: '',
    preferred_language: '',
    has_passed_quiz: false,
    in_telegram_group: false,
    course_progress: 'not_started'
  });

  // Fetch users on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  // Fetch users from supabase
  const fetchUsers = async () => {
    try {
      setLoading(true);
      
      // Get all users from users table
      const { data: userData, error: userError } = await getSupabase()
        .from('users')
        .select('*')
        .order('created_at', { ascending: false });
        
      if (userError) {
        console.error("Error fetching users:", userError);
        throw userError;
      }

      // Get all quiz results to enrich user data
      const { data: quizResults, error: quizError } = await getSupabase()
        .from('quiz_results')
        .select('*');
        
      if (quizError) {
        console.error("Error fetching quiz results:", quizError);
      }

      // Create a map of latest quiz results by user_id
      const quizMap = {};
      quizResults?.forEach(result => {
        if (result.user_id && (!quizMap[result.user_id] || new Date(result.created_at) > new Date(quizMap[result.user_id].created_at))) {
          quizMap[result.user_id] = result;
        }
      });

      // Get all payments
      const { data: paymentsData, error: paymentsError } = await getSupabase()
        .from('payments')
        .select('*');
        
      if (paymentsError) {
        console.error("Error fetching payments:", paymentsError);
      }

      // Process payments data
      const transactionMap = {};
      paymentsData?.forEach(payment => {
        if (!payment.user_id) return;
        
        if (!transactionMap[payment.user_id]) {
          transactionMap[payment.user_id] = {
            count: 0,
            total: 0,
            has_subscription: false,
            latest_payment: null,
            latest_payment_date: null
          };
        }
        
        const userTransactions = transactionMap[payment.user_id];
        userTransactions.count++;
        userTransactions.total += payment.amount || 0;
        
        if (!userTransactions.latest_payment_date || 
            new Date(payment.created_at) > new Date(userTransactions.latest_payment_date)) {
          userTransactions.latest_payment = payment;
          userTransactions.latest_payment_date = payment.created_at;
          userTransactions.payment_status = payment.status;
          userTransactions.confirmed_by = payment.confirmed_by;
        }
        
        if (payment.product_type === 'subscription' && payment.status === 'confirmed') {
          userTransactions.has_subscription = true;
          userTransactions.subscription_type = payment.subscription_type;
          userTransactions.subscription_start = payment.subscription_start;
          userTransactions.subscription_end = payment.subscription_end;
        }
      });

      // Combine all data
      const enrichedUsers = userData.map(user => {
        const quizData = quizMap[user.id];
        const transactionData = transactionMap[user.id] || {};
        
        return {
          ...user,
          has_passed_quiz: user.has_passed_quiz || quizData?.status === 'completed',
          transaction_count: transactionData.count || 0,
          transaction_total: transactionData.total || 0,
          has_subscription: transactionData.has_subscription || false,
          subscription_type: transactionData.subscription_type,
          subscription_start: transactionData.subscription_start,
          subscription_end: transactionData.subscription_end,
          payment_status: transactionData.payment_status,
          latest_payment: transactionData.latest_payment,
          latest_payment_date: transactionData.latest_payment_date,
          confirmed_by: transactionData.confirmed_by
        };
      });
      
      setUsers(enrichedUsers);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch transactions for a specific user
  const fetchTransactions = async (userId) => {
    try {
      setLoadingTransactions(true);
      
      // Fetch all payments for this user
      const { data: payments, error: paymentsError } = await getSupabase()
        .from('payments')
        .select('*, subscription_id')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });
        
      if (paymentsError) throw paymentsError;
      
      if (!payments || payments.length === 0) {
        setTransactions([]);
        return;
      }
      
      // Get subscription IDs to fetch plan details
      const subscriptionIds = payments
        .map(payment => payment.subscription_id)
        .filter(id => id);
        
      if (subscriptionIds.length > 0) {
        const { data: subscriptionsData, error: subError } = await getSupabase()
          .from('subscriptions')
          .select('id, plan_id')
          .in('id', subscriptionIds);
          
        if (!subError && subscriptionsData && subscriptionsData.length > 0) {
          // Create a map of subscription ID to plan ID
          const subscriptionPlanMap = {};
          subscriptionsData.forEach(sub => {
            subscriptionPlanMap[sub.id] = sub.plan_id;
          });
          
          // Enrich payments with plan information
          const enrichedPayments = payments.map(payment => {
            const planId = payment.subscription_id ? subscriptionPlanMap[payment.subscription_id] : null;
            const plan = planId && subscriptions[planId] ? subscriptions[planId] : null;
            
            return {
              ...payment,
              plan_name: plan ? plan.name?.[language] || plan.name?.ru || 'Unknown Plan' : null
            };
          });
          
          setTransactions(enrichedPayments);
        } else {
          setTransactions(payments);
        }
      } else {
        setTransactions(payments);
      }
    } catch (err) {
      console.error("Error fetching transactions:", err);
      toast.error(`${t.error}: ${err.message}`, {
        duration: 5000,
        position: 'top-right',
      });
    } finally {
      setLoadingTransactions(false);
    }
  };

  // Handle view transactions button click
  const handleViewTransactions = async (user) => {
    setViewingTransactions(user);
    await fetchTransactions(user.id);
  };

  // Handle assigning Telegram button click
  const handleAssignTelegram = async (user) => {
    const username = prompt(t.assignTelegram);
    if (!username) return;
    
    try {
      // Remove the @ symbol if included
      const cleanUsername = username.startsWith('@') ? username.substring(1) : username;
      
      const { error } = await getSupabase()
        .from('users')
        .update({ username: cleanUsername })
        .eq('id', user.id);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(u => 
        u.id === user.id 
          ? { ...u, username: cleanUsername } 
          : u
      ));
      
      toast.success(t.userUpdated, {
        duration: 3000,
        position: 'top-right',
      });
    } catch (err) {
      console.error("Error updating Telegram username:", err);
      toast.error(`${t.error}: ${err.message}`, {
        duration: 5000,
        position: 'top-right',
      });
    }
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (term) => {
      try {
        setLoading(true);
        
        // First, fetch the quiz results
        const { data: quizResults, error: quizError } = await getSupabase()
          .from('quiz_results')
          .select('*');
          
        if (quizError) {
          console.error("Error fetching quiz results:", quizError);
        }
        
        // Validate the user_id field in quiz_results
        const idField = quizResults && quizResults.length > 0 ? 
          (quizResults[0].user_id ? 'user_id' : 
           quizResults[0].user ? 'user' : 
           quizResults[0].userId ? 'userId' : null) : null;
        
        // Create a map of user_id to quiz result data
        const quizMap = {};
        if (quizResults && quizResults.length > 0 && idField) {
          quizResults.forEach(result => {
            const userId = result[idField];
            if (userId && !quizMap[userId]) {
              quizMap[userId] = {
                name: result.user_name || '',
                phone: result.user_phone || '',
                quiz_passed: result.status === 'completed'
              };
            }
          });
        }
        
        // Query for users
        let query = getSupabase().from('users').select('*');
        
        // Apply search filter if term is provided
        if (term) {
          query = query.or(
            `first_name.ilike.%${term}%,last_name.ilike.%${term}%,username.ilike.%${term}%,phone.ilike.%${term}%`
          );
        }
        
        const { data, error } = await query.order('created_at', { ascending: false });

        if (error) throw error;
        
        // If we have a search term that doesn't match any users directly,
        // try to find matches in quiz results
        if (term && (!data || data.length === 0) && idField) {
          const matchingUserIds = Object.entries(quizMap)
            .filter(([_, data]) => 
              (data.name && data.name.toLowerCase().includes(term.toLowerCase())) || 
              (data.phone && data.phone.includes(term))
            )
            .map(([id]) => id);
            
          if (matchingUserIds.length > 0) {
            const { data: quizUsers, error: quizUsersError } = await getSupabase()
              .from('users')
              .select('*')
              .in('id', matchingUserIds);
              
            if (!quizUsersError && quizUsers && quizUsers.length > 0) {
              // Merge with existing results
              data.push(...quizUsers);
            }
          }
        }
        
        // Enrich user data with quiz information
        const enrichedUsers = (data || []).map(user => {
          const quizData = quizMap[user.id] || {};
          return {
            ...user,
            quiz_name: quizData.name || '',
            quiz_phone: quizData.phone || '',
            has_passed_quiz: user.has_passed_quiz || quizData.quiz_passed || false,
            display_name: quizData.name || `${user.first_name || ''} ${user.last_name || ''}`.trim() || '—'
          };
        });
        
        setUsers(enrichedUsers);
      } catch (err) {
        console.error("Error searching users:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  // Format date to local format
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    
    const date = new Date(dateString);
    return date.toLocaleDateString(language === 'ru' ? 'ru-RU' : 'kk-KZ', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Handle edit button click
  const handleEditClick = (user) => {
    setEditingUser(user);
    setEditFormData({
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      username: user.username || '',
      phone: user.phone || '',
      zoom_email: user.zoom_email || '',
      preferred_language: user.preferred_language || 'ru',
      has_passed_quiz: user.has_passed_quiz || false,
      in_telegram_group: user.in_telegram_group || false,
      course_progress: user.course_progress || 'not_started'
    });
  };

  // Handle form input change
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setEditFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle setting quiz passed status
  const handleSetQuizStatus = async (userId, status) => {
    try {
      const { error } = await getSupabase()
        .from('users')
        .update({ has_passed_quiz: status })
        .eq('id', userId);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(user => 
        user.id === userId 
          ? { ...user, has_passed_quiz: status } 
          : user
      ));
    } catch (err) {
      console.error("Error updating quiz status:", err);
      toast.error(`${t.error}: ${err.message}`, {
        duration: 5000,
        position: 'top-right',
      });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Only include fields that actually exist in the database
      const updateData = {};
      
      if (editFormData.first_name !== undefined) updateData.first_name = editFormData.first_name;
      if (editFormData.last_name !== undefined) updateData.last_name = editFormData.last_name;
      if (editFormData.username !== undefined) updateData.username = editFormData.username;
      if (editFormData.phone !== undefined) updateData.phone = editFormData.phone;
      if (editFormData.zoom_email !== undefined) updateData.zoom_email = editFormData.zoom_email;
      if (editFormData.preferred_language !== undefined) updateData.preferred_language = editFormData.preferred_language;
      if (editFormData.has_passed_quiz !== undefined) updateData.has_passed_quiz = editFormData.has_passed_quiz;
      if (editFormData.in_telegram_group !== undefined) updateData.in_telegram_group = editFormData.in_telegram_group;
      if (editFormData.course_progress !== undefined) updateData.course_progress = editFormData.course_progress;
      
      // Avoid any timing fields like updated_at - the database will handle this
      console.log("Updating user with data:", updateData);
      
      const { error } = await getSupabase()
        .from('users')
        .update(updateData)
        .eq('id', editingUser.id);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(user => 
        user.id === editingUser.id 
          ? { 
              ...user, 
              ...updateData,
              display_name: user.quiz_name || `${updateData.first_name || ''} ${updateData.last_name || ''}`.trim() || '—'
            } 
          : user
      ));
      
      // Reset editing state
      setEditingUser(null);
      
      // Show success message
      toast.success(t.userUpdated, {
        duration: 3000,
        position: 'top-right',
      });
    } catch (err) {
      console.error("Error updating user:", err);
      toast.error(`${t.error}: ${err.message}`, {
        duration: 5000,
        position: 'top-right',
      });
    }
  };

  // Handle cancel button click
  const handleCancel = () => {
    setEditingUser(null);
  };

  // Handle delete button click
  const handleDelete = async (userId) => {
    if (!window.confirm(t.confirmDelete)) return;
    
    try {
      const { error } = await getSupabase()
        .from('users')
        .delete()
        .eq('id', userId);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.filter(user => user.id !== userId));
      
      // Show success message
      toast.success(t.userDeleted, {
        duration: 3000,
        position: 'top-right',
      });
    } catch (err) {
      console.error("Error deleting user:", err);
      toast.error(`${t.error}: ${err.message}`, {
        duration: 5000,
        position: 'top-right',
      });
    }
  };

  // Handle confirming a payment
  const handleConfirmPayment = async (userId, paymentId) => {
    try {
      // Get the current user's ID
      const { data: { user }, error: userError } = await getSupabase().auth.getUser();
      if (userError) throw userError;

      const { error } = await getSupabase()
        .from('payments')
        .update({ 
          status: 'confirmed',
          confirmed_by: user.id,
          confirmed_at: new Date().toISOString()
        })
        .eq('id', paymentId);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(user => {
        if (user.id === userId && user.latest_payment && user.latest_payment.id === paymentId) {
          const updated = { ...user };
          updated.payment_status = 'confirmed';
          updated.confirmed_by = user.id;
          if (updated.latest_payment) {
            updated.latest_payment.status = 'confirmed';
            updated.latest_payment.confirmed_by = user.id;
            updated.latest_payment.confirmed_at = new Date().toISOString();
          }
          return updated;
        }
        return user;
      }));
      
      toast.success(t.userUpdated, {
        duration: 3000,
        position: 'top-right',
      });
    } catch (err) {
      console.error("Error confirming payment:", err);
      toast.error(`${t.error}: ${err.message}`, {
        duration: 5000,
        position: 'top-right',
      });
    }
  };

  // Handle adding user to Telegram group
  const handleAddToGroup = async (userId) => {
    try {
      const { error } = await getSupabase()
        .from('users')
        .update({ in_telegram_group: true })
        .eq('id', userId);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(user => 
        user.id === userId 
          ? { ...user, in_telegram_group: true } 
          : user
      ));
      
      toast.success(t.userUpdated, {
        duration: 3000,
        position: 'top-right',
      });
    } catch (err) {
      console.error("Error updating Telegram group status:", err);
      toast.error(`${t.error}: ${err.message}`, {
        duration: 5000,
        position: 'top-right',
      });
    }
  };

  // Handle updating Zoom email
  const handleUpdateZoomEmail = async (userId) => {
    const email = prompt(t.zoomEmail);
    if (!email) return;
    
    try {
      const { error } = await getSupabase()
        .from('users')
        .update({ zoom_email: email })
        .eq('id', userId);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(u => 
        u.id === userId 
          ? { ...u, zoom_email: email } 
          : u
      ));
      
      toast.success(t.userUpdated, {
        duration: 3000,
        position: 'top-right',
      });
    } catch (err) {
      console.error("Error updating Zoom email:", err);
      toast.error(`${t.error}: ${err.message}`, {
        duration: 5000,
        position: 'top-right',
      });
    }
  };

  // Show user details
  const handleViewUserDetails = (user) => {
    setViewingUserDetails(user);
  };

  // Render loading state
  if (loading && !users.length) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-blue-600">{t.loading}</div>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="text-red-600 p-4">
        {t.error}: {error}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header Section */}
      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-900">{t.userManagement}</h1>
          <div className="flex space-x-4">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder={t.searchByNamePhoneTelegram}
              className="px-4 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 min-w-[300px]"
            />
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="text-blue-600">{t.loading}</div>
          </div>
        ) : error ? (
          <div className="text-red-600 p-4">
            {t.error}: {error}
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t.name}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t.telegramUsername}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t.phone}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t.quizStatus}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t.subscriptionInfo}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t.telegramGroup}
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t.actions}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map(user => (
                  <tr key={user.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {user.first_name || user.phone || 'Unnamed User'}
                      </div>
                      <div className="text-xs text-gray-500">
                        {formatDate(user.created_at)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {user.username ? (
                          <a 
                            href={`https://t.me/${user.username}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800"
                          >
                            @{user.username}
                          </a>
                        ) : (
                          <button
                            onClick={() => handleAssignTelegram(user)}
                            className="text-blue-600 hover:text-blue-800 text-sm"
                          >
                            {t.assignTelegram}
                          </button>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {user.phone || '—'}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => handleSetQuizStatus(user.id, !user.has_passed_quiz)}
                        className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          user.has_passed_quiz 
                            ? 'bg-green-100 text-green-800 hover:bg-green-200' 
                            : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                        }`}
                      >
                        {user.has_passed_quiz ? t.passed : t.notPassed}
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="space-y-1">
                        {user.has_subscription ? (
                          <>
                            <div className="text-xs px-2 py-1 bg-green-100 text-green-800 rounded">
                              {t.subscription} ({
                                user.subscription_type === '3_months' ? t.months_3 :
                                user.subscription_type === '6_months' ? t.months_6 :
                                user.subscription_type === '12_months' ? t.months_12 : ''
                              })
                            </div>
                            <div className="text-xs text-gray-500">
                              {t.expiresOn}: {formatDate(user.subscription_end)}
                            </div>
                            <div className="text-xs text-gray-500">
                              {new Date(user.subscription_end) > new Date() ? t.active : t.expired}
                            </div>
                          </>
                        ) : (
                          <div className="text-xs text-gray-500">{t.noSubscription}</div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {user.has_subscription ? (
                        <div>
                          {user.in_telegram_group ? (
                            <span className="inline-flex px-2 py-1 text-xs rounded-full bg-green-100 text-green-800">
                              {t.inGroup}
                            </span>
                          ) : (
                            <button
                              onClick={() => handleAddToGroup(user.id)}
                              className="text-blue-600 hover:text-blue-800 text-xs"
                            >
                              {t.addToGroup}
                            </button>
                          )}
                        </div>
                      ) : (
                        <span className="text-xs text-gray-500">—</span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <div className="flex justify-center space-x-2">
                        <button
                          onClick={() => handleEditClick(user)}
                          className="text-blue-600 hover:text-blue-900 text-sm"
                        >
                          {t.edit}
                        </button>
                        <button
                          onClick={() => handleViewTransactions(user)}
                          className="text-green-600 hover:text-green-900 text-sm"
                        >
                          {t.transactions}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Transaction Modal */}
      {viewingTransactions && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">
                  {t.transactionHistory} - {viewingTransactions.display_name}
                </h2>
                <button
                  onClick={() => setViewingTransactions(null)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </div>
              
              {loadingTransactions ? (
                <div className="flex justify-center items-center h-32">
                  <div className="text-blue-600">{t.loading}</div>
                </div>
              ) : transactions.length === 0 ? (
                <div className="text-gray-500 text-center py-8">
                  {t.noTransactions}
                </div>
              ) : (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        {t.amount}
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        {t.date}
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        {t.paymentMethod}
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        {t.paymentStatus}
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                        {t.actions}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {transactions.map(transaction => (
                      <tr key={transaction.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {transaction.amount} ₸
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {formatDate(transaction.created_at)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {transaction.payment_method || '—'}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            transaction.status === 'confirmed' 
                              ? 'bg-green-100 text-green-800' 
                              : transaction.status === 'pending_confirmation'
                              ? 'bg-yellow-100 text-yellow-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}>
                            {transaction.status === 'confirmed' ? t.confirmed :
                             transaction.status === 'pending_confirmation' ? t.pendingConfirmation :
                             t.notPaid}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {transaction.status === 'pending_confirmation' && (
                            <button
                              onClick={() => handleConfirmPayment(viewingTransactions.id, transaction.id)}
                              className="text-green-600 hover:text-green-900"
                            >
                              {t.confirmPayment}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Edit Modal */}
      {editingUser && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center p-4 z-50"
          style={{ 
            paddingTop: 'calc(15vh)',
            overflowY: 'auto',
            height: '100vh'
          }}
        >
          <div className="bg-white rounded-lg max-w-2xl w-full">
            <div className="sticky top-0 bg-white px-6 py-4 border-b flex justify-between items-center">
              <h2 className="text-xl font-semibold">
                {t.edit} - {editingUser.display_name}
              </h2>
              <button
                onClick={() => setEditingUser(null)}
                className="text-gray-500 hover:text-gray-700 text-2xl"
              >
                ×
              </button>
            </div>
            
            <div className="p-6">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t.firstName}
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      value={editFormData.first_name}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t.lastName}
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      value={editFormData.last_name}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t.telegramUsername}
                    </label>
                    <input
                      type="text"
                      name="username"
                      value={editFormData.username}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t.phone}
                    </label>
                    <input
                      type="text"
                      name="phone"
                      value={editFormData.phone}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t.language}
                    </label>
                    <select
                      name="preferred_language"
                      value={editFormData.preferred_language}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="ru">{t.russian}</option>
                      <option value="kz">{t.kazakh}</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t.courseProgress}
                    </label>
                    <select
                      name="course_progress"
                      value={editFormData.course_progress}
                      onChange={handleFormChange}
                      className="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="not_started">{t.notStarted}</option>
                      <option value="intro_completed">{t.introCompleted}</option>
                    </select>
                  </div>
                </div>

                <div className="flex justify-end space-x-3 mt-6">
                  <button
                    type="button"
                    onClick={() => setEditingUser(null)}
                    className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
                  >
                    {t.cancel}
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  >
                    {t.save}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement; 
