import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { LanguageProvider } from './contexts/LanguageContext';
import { AuthProvider } from './contexts/AuthContext';
import RequireAuth from './components/auth/RequireAuth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';

// Pages
import HomePage from './pages/HomePage';
import TestPage from './pages/TestPage';
import ResultsPage from './pages/ResultsPage';
import LoginPage from './pages/LoginPage';
import CoachPage from './pages/CoachPage';
import AdminPage from './pages/AdminPage';
import MigratePage from './pages/MigratePage';

// Create a client for React Query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  },
});

// Main App Component
function App() {
  // Simple component to test rendering
  const TestComponent = () => <div>Test Rendering</div>;

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <LanguageProvider>
            <Toaster
              containerClassName="toast-container"
              toastOptions={{
                className: 'toast',
                duration: 3000,
                position: 'top-center',
                style: {
                  background: '#fff',
                  color: '#333',
                  padding: '16px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  minWidth: '300px',
                  textAlign: 'center',
                  margin: '0 auto'
                },
                success: {
                  iconTheme: {
                    primary: '#10B981',
                    secondary: '#fff',
                  },
                },
                error: {
                  iconTheme: {
                    primary: '#EF4444',
                    secondary: '#fff',
                  },
                },
              }}
            />
            <style>{`
              .toast-container {
                position: fixed !important;
                top: 1rem !important;
                right: 0 !important;
                left: 0 !important;
                margin: 0 auto !important;
                pointer-events: none;
              }
              .toast {
                display: inline-flex !important;
                justify-content: center !important;
                margin: 0 auto !important;
                pointer-events: auto;
              }
            `}</style>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<HomePage />} />
              <Route path="/test" element={<TestPage />} />
              <Route path="/results/grid/:id" element={<ResultsPage view="grid" />} />
              <Route path="/results/list/:id" element={<ResultsPage view="list" />} />
              <Route path="/results/:id" element={<ResultsPage view="grid" />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/coach/login" element={<LoginPage />} />
              <Route path="/admin/login" element={<LoginPage />} />
              
              {/* Testing route */}
              <Route path="/test-render" element={<TestComponent />} />
              
              {/* Protected routes */}
              <Route 
                path="/coach/*" 
                element={
                  <RequireAuth role="coach">
                    <CoachPage />
                  </RequireAuth>
                } 
              />
              <Route 
                path="/admin/*" 
                element={
                  <RequireAuth role="admin">
                    <AdminPage />
                  </RequireAuth>
                } 
              />
              
              {/* Migration utility - admin only */}
              <Route 
                path="/migrate" 
                element={
                  <RequireAuth role="admin">
                    <MigratePage />
                  </RequireAuth>
                } 
              />
              
              {/* Fallback */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </LanguageProvider>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;