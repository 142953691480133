import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';
import AuthContext from '../contexts/AuthContext';
import { SparklesCore } from '../components/ui/sparkles';
import logo from '../assets/p18-logo.svg';
import DarkLanguageToggle from '../components/common/DarkLanguageToggle';

const translations = {
  title: {
    ru: 'Добро пожаловать',
    kz: 'Қош келдіңіз'
  },
  adminPortal: {
    ru: 'Панель администратора',
    kz: 'Әкімші панелі'
  },
  coachPortal: {
    ru: 'Панель тренера',
    kz: 'Жаттықтырушы панелі'
  },
  email: {
    ru: 'Электронная почта',
    kz: 'Электрондық пошта'
  },
  password: {
    ru: 'Пароль',
    kz: 'Құпия сөз'
  },
  login: {
    ru: 'Войти',
    kz: 'Кіру'
  },
  backToHome: {
    ru: 'Вернуться на главную',
    kz: 'Басты бетке қайту'
  },
  invalidCredentials: {
    ru: 'Неверные учетные данные',
    kz: 'Қате логин немесе құпия сөз'
  }
};

const LoginPage = () => {
  const { language } = useContext(LanguageContext);
  const { login, isAuthenticated, role } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  const isAdminLogin = location.pathname.includes('/admin');
  
  useEffect(() => {
    if (isAuthenticated()) {
      if (role === 'admin') {
        navigate('/admin/dashboard');
      } else if (role === 'coach') {
        navigate('/coach/dashboard');
      }
    }
  }, [isAuthenticated, role, navigate]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    
    try {
      const result = await login(email, password);
      
      if (result.success) {
        if (isAdminLogin && !result.is_admin) {
          setError('You do not have admin privileges');
          setLoading(false);
          return;
        }
        
        if (isAdminLogin) {
          navigate('/admin/dashboard');
        } else {
          navigate('/coach/dashboard');
        }
      } else {
        setError(result.error || translations.invalidCredentials[language]);
      }
    } catch (err) {
      console.error('Login error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className={`min-h-screen ${isAdminLogin ? 'bg-slate-950' : 'bg-[#1a365d]'} flex items-center justify-center p-4 relative overflow-hidden`}>
      {/* Sparkles */}
      <div className="absolute inset-0">
        <SparklesCore
          id="loginSparkles"
          background="transparent"
          minSize={0.4}
          maxSize={1}
          particleDensity={100}
          className="w-full h-full"
          particleColor="#FFFFFF"
          speed={0.5}
        />
      </div>

      <div className="max-w-md w-full relative z-10">
        {/* Logo and Title Section */}
        <div className="flex flex-col items-center mb-8">
          <div className="flex items-center justify-between w-full mb-4">
            <img 
              src={logo} 
              alt="P18" 
              className="h-16 w-auto text-white" 
              style={{ filter: 'brightness(0) invert(1)' }}
            />
            <DarkLanguageToggle />
          </div>
          <div className="w-full relative">
            <div className={`absolute inset-x-20 top-0 bg-gradient-to-r from-transparent ${isAdminLogin ? 'via-indigo-500' : 'via-blue-500'} to-transparent h-[2px] w-3/4 blur-sm`} />
            <div className={`absolute inset-x-20 top-0 bg-gradient-to-r from-transparent ${isAdminLogin ? 'via-indigo-500' : 'via-blue-500'} to-transparent h-px w-3/4`} />
            <h1 className="text-3xl font-bold text-center text-white mt-4">
              {isAdminLogin ? translations.adminPortal[language] : translations.coachPortal[language]}
            </h1>
          </div>
        </div>

        {/* Login Form */}
        <div className={`${isAdminLogin ? 'bg-slate-900/50' : 'bg-blue-900/50'} backdrop-blur-sm p-8 rounded-lg shadow-xl border ${isAdminLogin ? 'border-slate-800' : 'border-blue-800'}`}>
          <form onSubmit={handleSubmit} className="space-y-6" aria-label={isAdminLogin ? translations.adminPortal[language] : translations.coachPortal[language]}>
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2" htmlFor="email">
                {translations.email[language]}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`w-full px-4 py-2 ${isAdminLogin ? 'bg-slate-800 border-slate-700' : 'bg-blue-800 border-blue-700'} border rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 ${isAdminLogin ? 'focus:ring-indigo-500' : 'focus:ring-blue-500'} focus:border-transparent`}
                required
                aria-required="true"
                aria-invalid={error ? "true" : "false"}
                aria-describedby={error ? "login-error" : undefined}
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-2" htmlFor="password">
                {translations.password[language]}
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`w-full px-4 py-2 ${isAdminLogin ? 'bg-slate-800 border-slate-700' : 'bg-blue-800 border-blue-700'} border rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 ${isAdminLogin ? 'focus:ring-indigo-500' : 'focus:ring-blue-500'} focus:border-transparent`}
                required
                aria-required="true"
                aria-invalid={error ? "true" : "false"}
                aria-describedby={error ? "login-error" : undefined}
              />
            </div>
            
            {error && (
              <div 
                id="login-error" 
                role="alert" 
                className="mb-4 p-3 bg-red-900/50 text-red-200 rounded-md border border-red-800"
              >
                {error}
              </div>
            )}
            
            <button
              type="submit"
              disabled={loading}
              aria-disabled={loading}
              className={`w-full py-3 px-4 ${isAdminLogin ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-blue-600 hover:bg-blue-700'} text-white rounded-md font-medium transition-colors ${loading ? 'opacity-75 cursor-not-allowed' : ''}`}
            >
              {translations.login[language]}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;