import React, { useState } from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';

const translations = {
  comment: {
    ru: 'Комментарий',
    kz: 'Пікір'
  },
  commentPlaceholder: {
    ru: 'Введите комментарий...',
    kz: 'Пікіріңізді енгізіңіз...'
  },
  saveComment: {
    ru: 'Сохранить комментарий',
    kz: 'Пікірді сақтау'
  }
};

const CommentBox = ({ 
  resultId, 
  initialComment = '', 
  onSaveComment, 
  isSaving = false,
  className = ''
}) => {
  const { language } = useContext(LanguageContext);
  const [comment, setComment] = useState(initialComment);
  const [expanded, setExpanded] = useState(false);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSave = async () => {
    await onSaveComment(resultId, comment);
    setExpanded(false);
  };

  const textareaRows = Math.max(3, Math.ceil((comment?.length || 0) / 60));

  return (
    <div className={`p-2 bg-gray-50 rounded-md border border-gray-200 ${className}`}>
      {expanded ? (
        <div className="space-y-2">
          <label 
            htmlFor={`comment-${resultId}`} 
            className="text-xs text-gray-500 mb-1"
          >
            {translations.comment[language]}:
          </label>
          <textarea
            id={`comment-${resultId}`}
            name={`comment-${resultId}`}
            value={comment}
            onChange={handleCommentChange}
            placeholder={translations.commentPlaceholder[language]}
            rows={textareaRows}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
          />
          <button
            onClick={handleSave}
            disabled={isSaving}
            className={`px-3 py-1 rounded-md text-white text-xs font-medium ${
              isSaving
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
          >
            {translations.saveComment[language]}
          </button>
        </div>
      ) : (
        <div 
          className="cursor-pointer text-sm text-gray-600 hover:text-gray-800"
          onClick={() => setExpanded(true)}
        >
          {comment || translations.commentPlaceholder[language]}
        </div>
      )}
    </div>
  );
};

export default CommentBox; 