import { createClient } from '@supabase/supabase-js';

// Create the Supabase client with the provided credentials
const supabaseUrl = 'https://pjarqhshmfrjwelezdbj.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBqYXJxaHNobWZyandlbGV6ZGJqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDA3NzAxMjAsImV4cCI6MjA1NjM0NjEyMH0.3wbtZpbaISmV745HZ8gZxxuBM9ppUXTJxBSVtL-WpRg';
const supabaseServiceKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBqYXJxaHNobWZyandlbGV6ZGJqIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTc0MDc3MDEyMCwiZXhwIjoyMDU2MzQ2MTIwfQ.eaqH9iXVlszMDy6-J8UTcXqJcPwmZodZpI00qCdOjmE';

// Helper function to get JWT from cookies
function getJwtFromCookies() {
  if (typeof document === 'undefined') return null;
  
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('token=')) {
      return cookie.substring('token='.length, cookie.length);
    }
  }
  return null;
}

// Base options for both clients
const baseOptions = {
  db: {
    schema: 'public',
  },
  auth: {
    autoRefreshToken: false,
    persistSession: true,
    detectSessionInUrl: false,
    storageKey: 'p18-auth-token' // Single shared storage key
  },
  global: {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }
};

// Singleton instances
let _instance = null;
let _adminInstance = null;

// Create a single instance of the Supabase client
export function getSupabase() {
  if (_instance === null) {
    _instance = createClient(supabaseUrl, supabaseAnonKey, {
      ...baseOptions,
      global: {
        ...baseOptions.global,
        headers: {
          ...baseOptions.global.headers,
          Authorization: getJwtFromCookies() ? `Bearer ${getJwtFromCookies()}` : undefined
        }
      }
    });
  }
  return _instance;
}

// Create a single instance of the admin client
export function getSupabaseAdmin() {
  if (_adminInstance === null) {
    _adminInstance = createClient(supabaseUrl, supabaseServiceKey, baseOptions);
  }
  return _adminInstance;
}

// Function to update auth token - call this after login/logout
export const updateSupabaseAuth = () => {
  const instance = getSupabase();
  const jwt = getJwtFromCookies();
  
  if (jwt) {
    instance.rest.headers.Authorization = `Bearer ${jwt}`;
  } else {
    delete instance.rest.headers.Authorization;
  }
};

// Initialize auth on page load
if (typeof window !== 'undefined') {
  document.addEventListener('visibilitychange', updateSupabaseAuth);
  updateSupabaseAuth();
}

// Export a way to reset the instances if needed (useful for testing)
export const resetClients = () => {
  _instance = null;
  _adminInstance = null;
};