import React, { useState } from 'react';
import { format } from 'date-fns';
import StatusBadge from './StatusBadge';

/**
 * Result card component for displaying test results
 * @param {Object} props - Component props
 * @param {Object} props.result - Result data object
 * @param {Array} props.actions - Array of action objects [{label, onClick, color, icon}]
 * @param {Object} props.translations - Translations object
 * @param {string} props.language - Current language (ru/kz)
 * @param {boolean} props.showComment - Whether to show comment section
 * @param {Function} props.onCommentSave - Callback when comment is saved
 * @param {string} props.viewMode - Display mode ('grid' or 'list')
 * @param {boolean} props.isReviewed - Whether result has been reviewed
 * @param {Function} props.onReviewStatusChange - Callback when review status is changed
 * @param {Object} props.commentComponent - Optional custom comment component
 * @returns {JSX.Element} Rendered result card
 */
const ResultCard = ({
  result,
  actions = [],
  translations = {},
  language = 'ru',
  showComment = false,
  onCommentSave,
  viewMode = 'grid',
  isReviewed = false,
  onReviewStatusChange,
  commentComponent = null
}) => {
  const [expanded, setExpanded] = useState(false);
  
  // Get translation helper
  const getTranslation = (key) => {
    return translations[key] ? translations[key][language] : key;
  };
  
  // Format date helper
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'dd.MM.yyyy HH:mm');
    } catch (e) {
      return dateString;
    }
  };
  
  // Card content
  const cardContent = (
    <>
      <div className={`${viewMode === 'grid' ? 'mb-2' : 'flex items-center'} relative`}>
        {/* Review indicator - Moved to top right */}
        {onReviewStatusChange && (
          <div className="absolute top-2 right-2 flex items-center gap-2">
            <input
              type="checkbox"
              id={`mobile-review-status-${result.id}`}
              name={`mobile-review-status-${result.id}`}
              checked={result.is_reviewed}
              onChange={(e) => onReviewStatusChange(result.id, e.target.checked)}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label 
              htmlFor={`mobile-review-status-${result.id}`}
              className="text-sm text-gray-600 whitespace-nowrap"
            >
              {translations.reviewStatus[language]}
            </label>
          </div>
        )}

        <div className={viewMode === 'grid' ? 'pr-24' : 'flex-1'} role="article">
          {/* Name and email - prominent */}
          <h3 className="font-semibold text-gray-900" id={`client-name-${result.id}`}>{result.client_name || 'N/A'}</h3>
          <p className="text-sm text-gray-600 mb-1" id={`client-email-${result.id}`}>{result.client_email || 'N/A'}</p>
          
          {/* Additional details */}
          {viewMode === 'grid' && (
            <>
              <p className="text-sm text-gray-500 mb-1" id={`coach-info-${result.id}`}>
                {getTranslation('coach')}: {result.coach_name || result.coach_email || 'N/A'}
              </p>
              <div className="flex items-center mb-2">
                <p className="text-xs text-gray-500 mr-2" id={`date-${result.id}`}>{formatDate(result.created_at)}</p>
                <StatusBadge 
                  status={result.status} 
                  translations={translations} 
                  language={language}
                  id={`status-${result.id}`}
                />
              </div>
            </>
          )}
        </div>
        
        {/* Right side for list view - date and status */}
        {viewMode === 'list' && (
          <div className="flex items-center ml-4">
            <p className="text-sm text-gray-500 mr-3" id={`date-${result.id}`}>{formatDate(result.created_at)}</p>
            <StatusBadge 
              status={result.status} 
              translations={translations} 
              language={language}
              id={`status-${result.id}`}
            />
          </div>
        )}
      </div>
      
      {/* Actions */}
      <div className="flex flex-wrap gap-2 mt-2" role="toolbar" aria-label={getTranslation('actions') || 'Actions'}>
        {actions.map((action, index) => (
          <button
            key={index}
            onClick={() => action.onClick(result)}
            className={`text-sm px-3 py-1 rounded ${action.color || 'bg-blue-600 text-white'} hover:opacity-90`}
            aria-label={`${action.label} ${result.client_name}`}
          >
            {action.icon && <span className="mr-1" aria-hidden="true">{action.icon}</span>}
            {action.label}
          </button>
        ))}
      </div>

      {/* Comment section always visible at the bottom */}
      {showComment && (
        <div className="mt-4 pt-3 border-t border-gray-200" role="region" aria-label={getTranslation('comment') || 'Comment'}>
          <div className="text-sm text-gray-600 mb-2" id={`comment-label-${result.id}`}>
            {getTranslation('hasComment') || 'Comment'}:
          </div>
          {commentComponent ? (
            commentComponent
          ) : (
            <div>
              {result.comment ? (
                <p className="text-sm text-gray-700" id={`comment-${result.id}`}>{result.comment}</p>
              ) : (
                <p className="text-sm text-gray-500 italic" id={`comment-${result.id}`}>{getTranslation('noComment') || 'No comment'}</p>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );

  // Render card with appropriate layout based on viewMode
  if (viewMode === 'grid') {
    return (
      <div className="bg-white p-4 rounded-lg shadow-md" role="article" aria-labelledby={`client-name-${result.id}`}>
        {cardContent}
      </div>
    );
  } else {
    return (
      <div className="bg-white p-4 rounded-lg shadow-md" role="article" aria-labelledby={`client-name-${result.id}`}>
        {cardContent}
      </div>
    );
  }
};

export default ResultCard; 