import React, { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';

const LanguageToggle = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);
  
  return (
    <button
      onClick={toggleLanguage}
      className="flex items-center gap-2 text-sm font-medium text-gray-700 hover:bg-gray-50 px-3 py-2 rounded-lg transition-colors"
    >
      <span className="text-sm">{language === 'ru' ? '🇰🇿 KZ' : '🇷🇺 RU'}</span>
    </button>
  );
};

export default LanguageToggle;