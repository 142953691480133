import React, { useState, useContext, useEffect } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import { getSupabaseAdmin } from '../../supabase';

const translations = {
  addProduct: {
    ru: 'Добавить продукт',
    kz: 'Өнім қосу'
  },
  editProduct: {
    ru: 'Редактировать продукт',
    kz: 'Өнімді өңдеу'
  },
  nameRu: {
    ru: 'Название (Русский)',
    kz: 'Атауы (Орысша)'
  },
  nameKz: {
    ru: 'Название (Казахский)',
    kz: 'Атауы (Қазақша)'
  },
  descriptionRu: {
    ru: 'Описание (Русский)',
    kz: 'Сипаттама (Орысша)'
  },
  descriptionKz: {
    ru: 'Описание (Казахский)',
    kz: 'Сипаттама (Қазақша)'
  },
  price: {
    ru: 'Цена (₸)',
    kz: 'Бағасы (₸)'
  },
  type: {
    ru: 'Тип',
    kz: 'Түрі'
  },
  duration: {
    ru: 'Длительность (дней)',
    kz: 'Ұзақтығы (күн)'
  },
  active: {
    ru: 'Активный',
    kz: 'Белсенді'
  },
  subscription: {
    ru: 'Подписка',
    kz: 'Жазылым'
  },
  oneTime: {
    ru: 'Разовый платеж',
    kz: 'Бір реттік төлем'
  },
  save: {
    ru: 'Сохранить',
    kz: 'Сақтау'
  },
  cancel: {
    ru: 'Отмена',
    kz: 'Болдырмау'
  },
  required: {
    ru: 'Обязательное поле',
    kz: 'Міндетті өріс'
  }
};

const ProductForm = ({ product, onClose, onSave }) => {
  const { language } = useContext(LanguageContext);
  const [formData, setFormData] = useState({
    name_ru: '',
    name_kz: '',
    description_ru: '',
    description_kz: '',
    price: '',
    type: 'one_time',
    duration_days: '',
    is_active: true,
    default_price: '',
    currency: 'KZT'
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (product) {
      setFormData({
        name_ru: product.name_ru || '',
        name_kz: product.name_kz || '',
        description_ru: product.description_ru || '',
        description_kz: product.description_kz || '',
        price: product.price || '',
        type: product.type || 'one_time',
        duration_days: product.duration_days || '',
        is_active: product.is_active ?? true,
        default_price: product.default_price || '',
        currency: product.currency || 'KZT'
      });
    }
  }, [product]);

  const validate = () => {
    const newErrors = {};
    if (!formData.name_ru) newErrors.name_ru = translations.required[language];
    if (!formData.name_kz) newErrors.name_kz = translations.required[language];
    if (!formData.price) newErrors.price = translations.required[language];
    if (formData.type === 'subscription' && !formData.duration_days) {
      newErrors.duration_days = translations.required[language];
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setLoading(true);
    try {
      const productData = {
        ...formData,
        price: parseFloat(formData.price),
        default_price: parseFloat(formData.price),
        duration_days: formData.type === 'subscription' ? parseInt(formData.duration_days) : null,
        name: formData[`name_${language}`],
        description: formData[`description_${language}`]
      };

      console.log('Saving product with data:', productData);

      let result;
      if (product?.id) {
        console.log('Updating existing product with ID:', product.id);
        const { data, error } = await getSupabaseAdmin()
          .from('products')
          .update(productData)
          .eq('id', product.id)
          .select('*');

        if (error) {
          console.error('Supabase update error:', error);
          throw new Error(`Failed to update product: ${error.message}`);
        }
        console.log('Update response:', data);
        result = data[0];
      } else {
        console.log('Creating new product');
        const { data, error } = await getSupabaseAdmin()
          .from('products')
          .insert([productData]) // Wrap in array to ensure consistent response
          .select('*');

        if (error) {
          console.error('Supabase insert error:', error);
          throw new Error(`Failed to create product: ${error.message}`);
        }
        console.log('Insert response:', data);
        result = data[0];
      }

      if (!result) {
        throw new Error('No data returned from the server');
      }

      console.log('Operation successful, result:', result);
      onSave(result);
    } catch (error) {
      console.error('Detailed error:', error);
      alert(`Error saving product: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  return (
    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          {/* Russian Name */}
          <div>
            <label htmlFor="name_ru" className="block text-sm font-medium text-gray-700">
              {translations.nameRu[language]} *
            </label>
            <input
              type="text"
              name="name_ru"
              id="name_ru"
              value={formData.name_ru}
              onChange={handleChange}
              className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm
                ${errors.name_ru ? 'border-red-300' : 'border-gray-300'}`}
            />
            {errors.name_ru && (
              <p className="mt-1 text-sm text-red-600">{errors.name_ru}</p>
            )}
          </div>

          {/* Kazakh Name */}
          <div>
            <label htmlFor="name_kz" className="block text-sm font-medium text-gray-700">
              {translations.nameKz[language]} *
            </label>
            <input
              type="text"
              name="name_kz"
              id="name_kz"
              value={formData.name_kz}
              onChange={handleChange}
              className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm
                ${errors.name_kz ? 'border-red-300' : 'border-gray-300'}`}
            />
            {errors.name_kz && (
              <p className="mt-1 text-sm text-red-600">{errors.name_kz}</p>
            )}
          </div>

          {/* Russian Description */}
          <div>
            <label htmlFor="description_ru" className="block text-sm font-medium text-gray-700">
              {translations.descriptionRu[language]}
            </label>
            <textarea
              name="description_ru"
              id="description_ru"
              rows={3}
              value={formData.description_ru}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
            />
          </div>

          {/* Kazakh Description */}
          <div>
            <label htmlFor="description_kz" className="block text-sm font-medium text-gray-700">
              {translations.descriptionKz[language]}
            </label>
            <textarea
              name="description_kz"
              id="description_kz"
              rows={3}
              value={formData.description_kz}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
            />
          </div>

          {/* Price */}
          <div>
            <label htmlFor="price" className="block text-sm font-medium text-gray-700">
              {translations.price[language]} *
            </label>
            <input
              type="number"
              name="price"
              id="price"
              value={formData.price}
              onChange={handleChange}
              min="0"
              className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm
                ${errors.price ? 'border-red-300' : 'border-gray-300'}`}
            />
            {errors.price && (
              <p className="mt-1 text-sm text-red-600">{errors.price}</p>
            )}
          </div>

          {/* Type */}
          <div>
            <label htmlFor="type" className="block text-sm font-medium text-gray-700">
              {translations.type[language]}
            </label>
            <select
              name="type"
              id="type"
              value={formData.type}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
            >
              <option value="one_time">{translations.oneTime[language]}</option>
              <option value="subscription">{translations.subscription[language]}</option>
            </select>
          </div>

          {/* Duration (for subscription only) */}
          {formData.type === 'subscription' && (
            <div>
              <label htmlFor="duration_days" className="block text-sm font-medium text-gray-700">
                {translations.duration[language]} *
              </label>
              <input
                type="number"
                name="duration_days"
                id="duration_days"
                value={formData.duration_days}
                onChange={handleChange}
                min="1"
                className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm
                  ${errors.duration_days ? 'border-red-300' : 'border-gray-300'}`}
              />
              {errors.duration_days && (
                <p className="mt-1 text-sm text-red-600">{errors.duration_days}</p>
              )}
            </div>
          )}

          {/* Active Status */}
          <div className="flex items-center">
            <input
              type="checkbox"
              name="is_active"
              id="is_active"
              checked={formData.is_active}
              onChange={handleChange}
              className="h-4 w-4 rounded border-gray-300 text-blue-600"
            />
            <label htmlFor="is_active" className="ml-2 block text-sm text-gray-700">
              {translations.active[language]}
            </label>
          </div>
        </div>

        {/* Form Actions */}
        <div className="mt-6 flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
          >
            {translations.cancel[language]}
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
          >
            {translations.save[language]}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProductForm; 