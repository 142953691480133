import React, { useState, useContext, useEffect } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import { getSupabaseAdmin } from '../../supabase';
import ProductForm from './ProductForm';
import Modal from '../common/Modal';

const translations = {
  title: {
    ru: 'Управление продуктами',
    kz: 'Өнімдерді басқару'
  },
  addProduct: {
    ru: 'Добавить продукт',
    kz: 'Өнім қосу'
  },
  editProduct: {
    ru: 'Редактировать продукт',
    kz: 'Өнімді өңдеу'
  },
  name: {
    ru: 'Название',
    kz: 'Атауы'
  },
  description: {
    ru: 'Описание',
    kz: 'Сипаттама'
  },
  price: {
    ru: 'Цена',
    kz: 'Баға'
  },
  type: {
    ru: 'Тип',
    kz: 'Түрі'
  },
  duration: {
    ru: 'дней',
    kz: 'күн'
  },
  status: {
    ru: 'Статус',
    kz: 'Күйі'
  },
  actions: {
    ru: 'Действия',
    kz: 'Әрекеттер'
  },
  active: {
    ru: 'Активный',
    kz: 'Белсенді'
  },
  inactive: {
    ru: 'Неактивный',
    kz: 'Белсенді емес'
  },
  subscription: {
    ru: 'Подписка',
    kz: 'Жазылым'
  },
  oneTime: {
    ru: 'Разовый платеж',
    kz: 'Бір реттік төлем'
  },
  edit: {
    ru: 'Редактировать',
    kz: 'Өңдеу'
  },
  delete: {
    ru: 'Удалить',
    kz: 'Жою'
  },
  search: {
    ru: 'Поиск...',
    kz: 'Іздеу...'
  },
  confirmDelete: {
    ru: 'Вы уверены, что хотите удалить этот продукт?',
    kz: 'Бұл өнімді жойғыңыз келетініне сенімдісіз бе?'
  },
  cancel: {
    ru: 'Отмена',
    kz: 'Болдырмау'
  },
  loading: {
    ru: 'Загрузка...',
    kz: 'Жүктелуде...'
  },
  error: {
    ru: 'Произошла ошибка при загрузке данных',
    kz: 'Деректерді жүктеу кезінде қате пайда болды'
  },
  noProducts: {
    ru: 'Продукты не найдены',
    kz: 'Өнімдер табылмады'
  }
};

const ProductManagement = () => {
  const { language } = useContext(LanguageContext);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  // Get supabase admin instance
  const supabaseAdmin = getSupabaseAdmin();

  useEffect(() => {
    const testAdminAccess = async () => {
      try {
        console.log('Testing admin access...');
        const { data, error } = await supabaseAdmin
          .from('products')
          .select('count');
        
        if (error) {
          console.error('Admin access test failed:', error);
        } else {
          console.log('Admin access test successful:', data);
        }
      } catch (err) {
        console.error('Admin access test error:', err);
      }
    };

    testAdminAccess();
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      console.log('Fetching products...');
      const { data, error } = await supabaseAdmin
        .from('products')
        .select('*')
        .order('price', { ascending: true });

      if (error) {
        console.error('Error fetching products:', error);
        throw error;
      }
      console.log('Products fetched:', data);
      setProducts(data || []);
    } catch (error) {
      console.error('Error in fetchProducts:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (product) => {
    try {
      console.log('Handling save for product:', product);
      // First update the local state to immediately reflect the change
      setProducts(prevProducts => {
        const index = prevProducts.findIndex(p => p.id === product.id);
        if (index !== -1) {
          // Update existing product
          const newProducts = [...prevProducts];
          newProducts[index] = product;
          return newProducts;
        } else {
          // Add new product
          return [...prevProducts, product];
        }
      });

      // Then fetch fresh data from the server
      await fetchProducts();
      
      // Close the form
      setShowForm(false);
      setSelectedProduct(null);
    } catch (error) {
      console.error('Error in handleSave:', error);
      alert(`Error refreshing products: ${error.message}`);
    }
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setShowForm(true);
  };

  const handleDelete = async () => {
    if (!productToDelete) return;

    try {
      const { error } = await supabaseAdmin
        .from('products')
        .delete()
        .eq('id', productToDelete.id);

      if (error) throw error;
      await fetchProducts();
    } catch (error) {
      console.error('Error deleting product:', error);
      alert(error.message);
    } finally {
      setShowDeleteConfirm(false);
      setProductToDelete(null);
    }
  };

  const filteredProducts = products.filter(product =>
    product[`name_${language}`]?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product[`description_${language}`]?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product[`name_${language === 'ru' ? 'kz' : 'ru'}`]?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product[`description_${language === 'ru' ? 'kz' : 'ru'}`]?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
    </div>;
  }

  if (error) {
    return <div className="text-red-600 p-4">{translations.error[language]}</div>;
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">{translations.title[language]}</h1>
        <button
          onClick={() => setShowForm(true)}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          {translations.addProduct[language]}
        </button>
      </div>

      <div className="mb-6">
        <input
          type="text"
          placeholder={translations.search[language]}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border rounded-md"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredProducts.map(product => (
          <div
            key={product.id}
            className="bg-white rounded-lg shadow-md p-4 border"
          >
            <div className="flex justify-between items-start mb-2">
              <div>
                <h3 className="text-lg font-semibold">
                  {product[`name_${language}`]}
                  <span className="text-sm text-gray-500 ml-2">
                    ({product[`name_${language === 'ru' ? 'kz' : 'ru'}`]})
                  </span>
                </h3>
                <div className="text-sm text-gray-600 mt-1">
                  {product[`description_${language}`]}
                </div>
              </div>
              <div className="flex items-center">
                <span className={`px-2 py-1 rounded text-xs ${product.is_active ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'}`}>
                  {product.is_active ? translations.active[language] : translations.inactive[language]}
                </span>
              </div>
            </div>

            <div className="mt-4 space-y-2">
              <div className="flex justify-between items-center">
                <span className="text-gray-600">{translations.price[language]}:</span>
                <span className="font-semibold">{product.price} {product.currency}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">{translations.type[language]}:</span>
                <span>{product.type === 'subscription' ? translations.subscription[language] : translations.oneTime[language]}</span>
              </div>
              {product.type === 'subscription' && (
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">{translations.duration[language]}:</span>
                  <span>{product.duration_days} {translations.duration[language]}</span>
                </div>
              )}
            </div>

            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => handleEdit(product)}
                className="px-3 py-1 text-sm bg-blue-100 text-blue-700 rounded hover:bg-blue-200"
              >
                {translations.edit[language]}
              </button>
              <button
                onClick={() => {
                  setProductToDelete(product);
                  setShowDeleteConfirm(true);
                }}
                className="px-3 py-1 text-sm bg-red-100 text-red-700 rounded hover:bg-red-200"
              >
                {translations.delete[language]}
              </button>
            </div>
          </div>
        ))}
      </div>

      {showForm && (
        <Modal 
          isOpen={showForm}
          title={selectedProduct ? translations.editProduct[language] : translations.addProduct[language]}
          onClose={() => {
            setShowForm(false);
            setSelectedProduct(null);
          }}
        >
          <ProductForm
            product={selectedProduct}
            onClose={() => {
              setShowForm(false);
              setSelectedProduct(null);
            }}
            onSave={handleSave}
          />
        </Modal>
      )}

      {showDeleteConfirm && (
        <Modal 
          isOpen={showDeleteConfirm}
          title={translations.confirmDelete[language]}
          onClose={() => {
            setShowDeleteConfirm(false);
            setProductToDelete(null);
          }}
        >
          <div className="p-6">
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => {
                  setShowDeleteConfirm(false);
                  setProductToDelete(null);
                }}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                {translations.cancel[language]}
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                {translations.delete[language]}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ProductManagement; 